import {
  ModalNames,
  ACCOUNT_ACTIVATION_EMAIL_SENT,
  ACCOUNT_CONFIRMED_ERROR,
  ACCOUNT_CONFIRMED_SUCCESS,
  ACCOUNT_ALREADY_CONFIRMED,
  ACCOUNT_CREATED_SUCCESS,
  ADD_TO_COLLECTION,
  NEWSLETTER,
  REVIEW,
  FOLLOWEES_RATING,
  EXPORT_PLAYER,
  SHARE_MODAL
} from 'common/module/ui/lazy-modal/constant-types';

/**
 * Fetches using Webpack dynamic import the given modal module
 *
 * @param {string} modalModuleName The name of the modal module to fetch (must match a known module)
 * @return {Promise} A Promise resolved with the module's default export or rejected (if the module name is not known)
 */
export default async function fetchModalModule(modalModuleName: string) {
  let modalModule;
  switch (modalModuleName) {
    case NEWSLETTER:
      modalModule = await import(
        /* webpackChunkName: "newsletter-modal" */ 'website/components/push/Newsletter/modal-newsletter'
      );
      break;
    case REVIEW:
      modalModule = await import(
        /* webpackChunkName: "review-modal" */ 'website/components/opinion/review/ReviewModal'
      );
      break;
    case FOLLOWEES_RATING:
      modalModule = await import(
        /* webpackChunkName: "followees-rating-modal" */ 'website/components/user/FolloweesRatingModal'
      );
      break;
    case ACCOUNT_CREATED_SUCCESS:
      modalModule = await import(
        /* webpackChunkName: "account-created-success-modal" */ 'website/components/user/account/AccountCreatedSuccessModal'
      );
      break;
    case ACCOUNT_CONFIRMED_SUCCESS:
      modalModule = await import(
        /* webpackChunkName: "account-confirmed-success-modal" */ 'website/components/user/account/AccountConfirmedSuccessModal'
      );
      break;
    case ACCOUNT_CONFIRMED_ERROR:
      modalModule = await import(
        /* webpackChunkName: "account-confirmed-error-modal" */ 'website/components/user/account/AccountConfirmedErrorModal'
      );
      break;
    case ACCOUNT_ALREADY_CONFIRMED:
      modalModule = await import(
        /* webpackChunkName: "account-already-confirmed-modal" */ 'website/components/user/account/AccountAlreadyConfirmedModal'
      );
      break;
    case ACCOUNT_ACTIVATION_EMAIL_SENT:
      modalModule = await import(
        /* webpackChunkName: "account-activation-email-sent-modal" */ 'website/components/user/account/AccountActivationEmailSentModal'
      );
      break;
    case ModalNames.ACCOUNT_NEEDS_CONFIRMATION:
      modalModule = await import(
        /* webpackChunkName: "account-needs-confirmation-modal" */ 'website/components/user/account/AccountNeedsConfirmationModal'
      );
      break;
    case ModalNames.TOS_VALIDATION_MODAL:
      modalModule = await import(
        /* webpackChunkName: "tos-validation-modal" */ 'common/components/TOSValidationModal'
      );
      break;
    case EXPORT_PLAYER:
      modalModule = await import(
        /* webpackChunkName: "export-player-modal" */ 'website/components/player/ExportModal'
      );
      break;
    case SHARE_MODAL:
      modalModule = await import(
        /* webpackChunkName: "share-modal" */ 'common/components/ShareModal'
      );
      break;
    case ADD_TO_COLLECTION:
      modalModule = await import(
        /* webpackChunkName: "add-to-collection-modal" */
        'website/components/user/AddToCollectionModal'
      );
      break;
    case ModalNames.PROMOTE_AFFINITY:
      modalModule = await import(
        /* webpackChunkName: "promote-affinity-modal" */
        'website/components/user/PromoteAffinityModal'
      );
      break;
    case ModalNames.PROMOTE_SIGNUP:
      modalModule = await import(
        /* webpackChunkName: "promote-signup-modal" */ 'website/components/user/PromoteSignupModal'
      );
      break;
    case ModalNames.SHOWTIME_MODAL:
      modalModule = await import(
        /* webpackChunkName: "showtime-modal" */ 'common/components/ShowtimeModal'
      );
      break;
    default:
      return Promise.reject(
        new Error(`Unknown modal module ${modalModuleName}`)
      );
  }
  return modalModule.default;
}
