import {
  IMAGES_SHARD_BASENAME,
  IMAGES_SHARD_COUNT
} from 'common/configuration/constants';

/**
 * Parse an url and returns its composing parts
 * @param  {String} url the url to analyze
 * @return {Object}     the different parts of the url
 */
export function urlParts(url: string) {
  const link = document.createElement('a');

  //  set href to any path
  link.setAttribute('href', url);

  return {
    host: link.hostname, //  'example.com'
    port: link.port, //  12345
    search: link.search, //  {startIndex: 1, pageSize: 10}
    path: link.pathname, //  '/blog/foo/bar'
    protocol: link.protocol //  'http:'
  };
}

/**
 * Returns Second Level Domain of the given hostname
 *
 * @param {String} hostname The hostname as return by window.location.hostname
 * @return {String} Second Level Domain or empty string
 */
export function getRootDomain(hostname: string) {
  if (typeof hostname !== 'string') return '';
  const match = (hostname || '').match(
    /[a-z0-9][a-z0-9-]*[a-z0-9]\.[a-z.]{2,6}$/i
  );
  return match ? match[0] : '';
}

/**
 * Computes which shard to use for a given url
 * @param  {String} path url path
 * @param  {String} protocol URL protocol
 * @return {String}     The sharded url
 */
export function shardUrl(path: string, protocol = '') {
  let total = 0;

  for (let i = 0; i < path.length; i++) {
    total += path.charCodeAt(i);
  }
  const serverChoice =
    total % IMAGES_SHARD_COUNT
      ? (total % IMAGES_SHARD_COUNT) + 1
      : IMAGES_SHARD_COUNT;
  const host = `//${IMAGES_SHARD_BASENAME}.web.img${serverChoice}.acsta.net`;
  return protocol + host + path;
}

type Options = {
  src?: string;
  protocol?: 'http' | 'https';
  format?: {
    cw?: number;
    ch?: number;
    grow?: boolean;
    rw?: number;
    rh?: number;
    bw?: number;
    bc?: number;
    of?: number;
    oo?: number;
    op?: number;
    f?: 'png' | 'jpg';
    q?: number;
  };
};

/**
 * Formats an image url with crop / filter / format options
 * @param  {Object} options Image options
 * @return {String}         the url
 */
export function getImageUrl(options: Options = {}) {
  if (!options || !options.src) {
    return '';
  }

  const format = options.format;

  const seg: string[] = [];
  if (format && format.cw && format.ch) {
    seg.push(
      'c' + (format.grow ? 'x' : '') + '_' + format.cw + '_' + format.ch
    );
  } else if (format && (format.rw || format.rh)) {
    seg.push(
      'r' +
        (format.grow ? 'x' : '') +
        '_' +
        (format.rw || 'x') +
        '_' +
        (format.rh || 'x')
    );
  }
  if (format && (format.bw || format.bc)) {
    seg.push('b_' + (format.bw || 'x') + '_' + (format.bc || 'x'));
  }
  if (format && format.of) {
    seg.push(
      'o_' +
        encodeURIComponent(format.of).toLowerCase() +
        '_' +
        (format.oo || 'x') +
        '_' +
        (format.op || 'c')
    );
  }
  if (format && format.f && format.f.toLowerCase() !== 'jpg') {
    seg.push('f_' + format.f.toLowerCase());
  }
  if (format && format.q && format.q !== 70) {
    seg.push('q_' + format.q);
  }

  const path = (seg.length ? '/' + seg.join('/') : '') + options.src;
  return shardUrl(path, options.protocol);
}

/**
 * JavaScript Get URL Parameter
 *
 * @param {String} url The specific URL parameter you want to retreive the value for
 * @return {Object|undefined} An object of all properties is returned
 */
export function getUrlParams(url: string) {
  if (url.indexOf('?') === -1) return undefined;

  // stuff after # is not part of query string, so get rid of it
  const queryString = url.split('#')[0];
  const params: { [key: string]: string | undefined } = {};
  const search = queryString.slice(queryString.indexOf('?') + 1);

  if (search.length === 0) return undefined;

  const definitions = search.split('&');

  for (const definition of definitions) {
    const parts = definition.split('=', 2);
    params[parts[0]] = parts[1] ? decodeURIComponent(parts[1]) : undefined;
  }

  return params;
}

/**
 * JavaScript check if url has param in query
 *
 * @param {string} url The specific URL parameter you want to check the value for
 * @param {string} prop pram name to check
 * @returns {boolean} bool url has param prop or not
 */
export function urlHasParam(url: string, prop: string) {
  const params = getUrlParams(url);

  return !!(params && prop in params);
}

export const removeScheme = (url: string) =>
  url && url.replace(/.*(\/\/.*)/, '$1');

export function addUrlParam(url: string, param: string, value: any): string {
  let u: URL;
  try {
    u = new URL(url);
  } catch {
    return url;
  }
  const searchParams = new URLSearchParams(u.search);
  searchParams.set(param, value);
  u.search = searchParams.toString();
  return u.toString();
}

export const getUrlParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};
