/* add classes dependencies */
import { addClass, removeClass } from 'common/tools/dom/classes';
import { on, off, delegate } from 'common/tools/dom/events';

class Overlay {
  constructor() {
    // DOM Element refrence
    this.html = document.getElementsByTagName('html')[0];

    // Futur dom reference to create Overlay
    this.container = null;
    this.content = null;
    this.modalLayer = null;
    this.closeBtn = null;

    // Control vars
    this.isInjected = false;
    this.isCreated = false;
    this.isOpen = false;
    this.type = null;
  }

  // global Methods
  create(closeHandler) {
    if (this.isCreated) {
      return false;
    }

    this.container = document.createElement('div');
    this.content = document.createElement('div');
    this.closeBtn = document.createElement('div');

    /* eslint-disable no-multi-assign */
    this.container.id = this.container.className = 'overlay';
    this.content.id = this.content.className = 'overlay-content';
    this.closeBtn.id = this.closeBtn.className =
      'overlay-close js-trigger-overlay-close icon icon-cross';
    /* eslint-enable no-multi-assign */

    this.container.appendChild(this.content);
    this.container.appendChild(this.closeBtn);

    const closeHdl = closeHandler || this.close.bind(this);

    delegate(this.container, 'js-trigger-overlay-close', 'click', () => {
      closeHdl();
    });
    // binded functions
    this.closeOlbyEsc = function closeOlbyEsc(e) {
      if (e.keyCode === 27) {
        closeHdl();
      }
    };

    // control creation
    this.isCreated = true;
  }

  setContent(content) {
    if (typeof content === 'string') {
      this.content.innerHTML = content;
    } else {
      this.content.appendChild(content);
    }
  }

  inject() {
    if (this.isInjected) {
      return false;
    }

    document.body.appendChild(this.container);
    this.isInjected = true;
  }

  empty() {
    this.content.innerHTML = '';
  }

  show(type) {
    if (this.isOpen) {
      return false;
    }
    if (type === 'modal') {
      addClass(this.html, 'open-modal');
      this.type = 'modal';
    } else {
      addClass(this.html, 'open-overlay');
    }

    on(window, 'keydown', this.closeOlbyEsc);

    this.isOpen = true;
  }

  hide() {
    if (!this.isOpen) {
      return false;
    }
    if (this.type === 'modal') {
      removeClass(this.html, 'open-modal');
    } else {
      removeClass(this.html, 'open-overlay');
    }

    off(window, 'keydown', this.closeOlbyEsc);

    this.type = null;
    this.isOpen = false;
  }

  close = () => {
    this.hide();
    this.empty();
  };

  // globla init with controls
  init(content, type) {
    this.create();
    this.setContent(content);
    this.inject();
    this.show(type);
  }
}

const overlay = new Overlay();

export default overlay;
