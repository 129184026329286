import { ModalNames } from 'common/module/ui/lazy-modal/constant-types';
import { hit } from 'common/tracking/googleanalytics';
import { standardizeTrackingType } from 'common/tracking/helpers';

import { AccountNeedsConfirmationModalParams } from 'website/services/modal/types';

type Params<T extends ModalNames> =
  T extends ModalNames.ACCOUNT_NEEDS_CONFIRMATION
    ? AccountNeedsConfirmationModalParams
    : undefined;

export const trackOpenModal = <T extends ModalNames>(
  modalName: T,
  params: Params<T>
) => {
  if (modalName === ModalNames.ACCOUNT_NEEDS_CONFIRMATION) {
    const { trackingContext } = params as AccountNeedsConfirmationModalParams;

    hit(
      {
        eventAction: trackingContext?.eventAction || 'impression_modal',
        eventCategory: 'social',
        eventLabel: 'account_activation',
        dimension84: standardizeTrackingType(trackingContext?.entityTypename)
      },
      true
    );
  } else if (modalName === ModalNames.PROMOTE_AFFINITY) {
    hit(
      {
        eventAction: 'pop_up',
        eventCategory: 'recommandation_program',
        eventLabel: 'impression'
      },
      true
    );
  } else if (modalName === ModalNames.PROMOTE_SIGNUP) {
    hit(
      {
        eventAction: 'pop_up',
        eventCategory: 'recommandation_signup',
        eventLabel: 'impression'
      },
      true
    );
  }
};

export const trackCloseModal = (modalName: ModalNames) => {
  if (modalName === ModalNames.PROMOTE_AFFINITY) {
    hit(
      {
        eventAction: 'pop_up',
        eventCategory: 'recommandation_program',
        eventLabel: 'close'
      },
      true
    );
  } else if (modalName === ModalNames.PROMOTE_SIGNUP) {
    hit(
      {
        eventAction: 'pop_up',
        eventCategory: 'recommandation_signup',
        eventLabel: 'close'
      },
      true
    );
  }
};
