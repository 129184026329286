export class RequestError extends Error {
  data: any;
  response: Response | null = null;
}

type RequestParameters<T extends { [key: string]: any }> = Omit<
  RequestInit,
  'body'
> & { payload?: T | null };

/**
 * Converts a payload to a readable format for fetch :
 *   - FormData object if available
 *   - string encoded like jquery.param : bar=foo&baz=1 ...
 * @param  {Object} parameters - the request parameters to convert
 * @return {FormData|String} - the payload body
 */
function getRequestBody<T extends { [key: string]: any }>(
  parameters: RequestParameters<T>
) {
  if (
    parameters.headers &&
    'Content-Type' in parameters.headers &&
    parameters.headers['Content-Type'] === 'application/json'
  ) {
    return JSON.stringify(parameters.payload);
  }

  const formData = new FormData();

  Object.entries(parameters.payload ?? {}).forEach(([key, val]) => {
    formData.append(key, val);
  });

  return formData;
}

function needsBody<T extends Record<string, unknown>>(
  requestParameters: RequestParameters<T>
) {
  return (
    requestParameters.payload &&
    (requestParameters.method === 'POST' ||
      requestParameters.method === 'PATCH')
  );
}

/**
 * Makes a request using Fetch (polyfilled if needed)
 * @param  {String} ressourceUrl      url of the distant ressource
 * @param  {Object} requestParameters Requests parameters
 * @param  {Boolean} json Set to false to avoid a JSON.parse of the response
 * @return {Promise}                  A promise representing request
 */
export default function request<
  ResponseType = any,
  PayloadType extends { [key: string]: any } = any
>(
  ressourceUrl: string,
  requestParameters: RequestParameters<PayloadType> = {},
  json = true
) {
  const fetchParameters: RequestInit = {
    ...requestParameters
  };
  if (needsBody(requestParameters)) {
    fetchParameters.body = getRequestBody(requestParameters);
  }
  return fetch(ressourceUrl, fetchParameters)
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      }
      const error = new RequestError(
        `${response.statusText} on ${ressourceUrl}`
      );
      const contentType = response.headers.get('Content-Type');
      // handle errors responses containing a json body
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then(data => {
          error.data = data;
          throw error;
        });
      }
      error.data = {};
      error.response = response;
      throw error;
    })
    .then(response => {
      if (json && response.status !== 204) {
        return response.json() as Promise<ResponseType>;
      }
      // Forcing type to ResponseType because fetch does not allow to specify response type
      return response as unknown as ResponseType;
    });
}
