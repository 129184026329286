// This module needs to be aliased in Babel to point to the correct file
import fetchModalModule from 'fetch-modal-modules';

import Overlay from 'common/module/ui/Overlay';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import isVisible from 'common/tools/dom/isVisible';
import { isProd } from 'common/tools/env';

export type CloseModal = (name?: string) => void;

class LazyModal {
  _askBeforeClosing: boolean;
  _askBeforeClosingMessage?: string;
  _module?: ModalModule;
  _noClose: boolean;

  constructor() {
    this._askBeforeClosing = false;
    this._module = undefined;
    this._noClose = false;
    Overlay.create(this.close.bind(this));
  }

  async open(
    modalName: string,
    params?: Record<string, unknown>,
    noClose = false
  ) {
    let modalModule;
    try {
      // Fetching the module corresponding to the given name
      modalModule = await fetchModalModule(modalName);
    } catch (e) {
      if (!isProd() && e instanceof Error) {
        console.error(e.message); // eslint-disable-line no-console
      }
      return;
    }
    Overlay.inject();

    this.close(); // Closing any previously open modal
    this._module = modalModule;
    this._module.render(
      Overlay.content as HTMLDivElement,
      params ?? null,
      this.close.bind(this),
      this._markAskBeforeClosing
    );
    this._noClose = noClose;

    // If the module wants to perform special treatment when open
    this._module?.onOpen?.();

    Overlay.show('modal');
    isVisible(Overlay.container as HTMLDivElement).then(visible => {
      if (visible) eventEmitter.emit(EventsTypes.OPEN_MODAL, modalName, params);
    });
  }

  close() {
    if (!Overlay.isOpen) return; // The modal is not even open
    if (this._noClose) return; // The current open modal has prevented closing
    if (
      this._askBeforeClosing &&
      // eslint-disable-next-line no-alert
      !window.confirm(this._askBeforeClosingMessage)
    )
      return;

    this._module?.onClose?.(); // If the module wants to perform stuff such as tracking

    this._module?.remove(Overlay.content as HTMLDivElement); // Unmount the module

    this._askBeforeClosing = false;
    this._askBeforeClosingMessage = undefined;
    Overlay.close();
    eventEmitter.emit(EventsTypes.CLOSE_MODAL, this._module?.name);
  }

  _markAskBeforeClosing = (askBeforeClosing: boolean, message: string) => {
    this._askBeforeClosing = askBeforeClosing;
    this._askBeforeClosingMessage = message;
  };
}

const lazyModal = new LazyModal();

export default lazyModal;
